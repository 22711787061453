.imageBGContainer {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    overflow: hidden;
  
}
.mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    background-color: #03002C;
    padding-block: 16px;
    padding-inline: 5%;
    border-radius: 6px;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    border: solid #1100D0;
    border-width: 0 1px 1px 1px;
}
.spaceAnoaLogoContainer{
    /* flex w-[15%] */
    display: flex;
    width: fit-content;
    background-color: antiquewhite;
}
.headerMenuContainer{
     display: flex;
     /* justify-content: space-between; */
     align-items: center;
     width: 50%;
     height: 6vh;
     /* background-color: aqua; */
     /* padding-left: 5%; */
     /* padding-right: 5%; */
     /* justify-content: flex-end; */
     /* justify-content: flex-end; */
}
.mainInput{
    width: 100%;
    height: 70%;
    border-radius: 6px;
    padding-left: 5%;
}
.headerButtonActionContainer{
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
     width: 60%;
     padding-right: 2%;
     height: 100%;
     align-items: center;
     gap: 5%;
     background-color: red;
}
/* .buttonContainer
{
    display: flex;
    flex-direction: row;
    gap: 3%;
    width: inherit;
    background-color: #FF0000;
} */
.linkHeaderContainer{
    display: flex;
    flex-direction: row;
    /* gap: 30%; */
    /* padding-right: 10%; */
}
.loginButton{
    background: linear-gradient(269.25deg, #7B7CFC 1.75%, #2526A5 51.41%, #10118F 99.88%);
    border-radius: 12px;
    color: white;
    font-size: 100%;
    /* line-height: 28px; */
    width: 35%;
    height: 80%;

}
.registerButton{
    background: linear-gradient(269.25deg, #FFA5AD 1.75%, #FF0000 51.41%, #820202 99.88%);
    border-radius: 12px;
    color: white;
    font-size: 100%;
    width: 35%;
    height: 80%;

}
/* HEADER MAIN CONTENT SECTION */
.FirstContentContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* min-width: 200px; */
    background-color: #03002C;
    /* background-color: #7B7CFC; */
    padding: 1.5vw;
   
}
.categoryButtonContainer {
    display: flex;
    flex-direction: column;
    /* height: 10%; */
}
.categoryButtonDefault {
    display: flex;
    /* background-color: #4C4DDC ; */
    margin-bottom: 10%;
    width: 100%;
    /* height: 7%; */
    justify-content: flex-start;
    padding-inline: 5%;
    padding-block: 10%;
    border-radius: 6px;
    align-items: center;
}
.categoryButtonChoosen {
    display: flex;
    background-color: #4C4DDC ;
    width: 100%;
    margin-bottom: 10%;
    /* height: 7%; */
    justify-content: flex-start;
    /* padding-left: 5%; */
    padding-inline: 5%;
    padding-block: 10%;
    border-radius: 6px;
    align-items: center;
}
.FirstLeftContent{
    /* flex flex-col w-[50%] pt-[15%] */
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    gap: 5%;
    /* paddingt */
}
.FirstLeftContentTitle {
    font-weight: 600;
    font-size: 64px;
    line-height: 72px;
    color: white;
}
.FirstLeftContentMessage {
    font-weight: 400px;
    font-size: 24px;
    line-height: 24px;
    color: #BABEC1;

}
.StartButton {
    /* bg-bm2 rounded-xl py-[3%]  text-white w-[45%] mt-[5%] */
    background: linear-gradient(269.25deg, #7B7CFC 1.75%, #2526A5 51.41%, #10118F 99.88%);
    border-radius: 12px;
    /* padding-left: 3%; */
    width: 100%;
    padding-block: 10%;
    /* height: 2.25%; */
    color: white;

}
.linkTextStyle{
    color: white;
    font-size: 100%;
}
.FirstRightContent{
    /* background-color: #2526A5; */
    /* flex w-[50%] justify-flex-end mt-[5%] */
    display: flex;
    width: 50%;
    justify-content: flex-end;
}
.carouselContainer {
    /*display: flex;
    flex-direction: column;*/
    height: 50vh;
    margin-bottom: 0;
    background-color: #041640;
    /* background-color: red; */
    border: #041640 1px;
    padding: 2%;
    border-radius: 24px;
}
.caroImageContainer {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */ 
    background: #000;
    height: 40vh;
    width: 100%;
    /* background-color: red; */

}
.descStyle{
    margin-top: 5%;
    font-size: medium;
}
.caroInfoBox{
    /* overflow-y: scroll; */
    /* height: 80%; */
    display: flex;
    padding-inline: 3%;
    padding-block: 3%;
    flex-direction: column;
    text-align: justify;
    width: 40%;
    /* justify-content: space-between; */
    background-color: #24344B ;
    color: white;
}
.arrowStylesNext{
    position: 'absolute';
    z-index: 2;
    top: 'calc(50% - 15px)';
    width: 30;
    height: 30;
    cursor: 'pointer';
    left: 15;
    background:  red;
}
.arrowStylesPrev{
    position: 'absolute';
    z-index: 2;
    top: 'calc(50% - 15px)';
    width: 30;
    height: 30;
    cursor: 'pointer';
    right: 15;
    background-color:  red;
}
.indicatorStyles{
    background: '#fff';
        width: 8;
        height: 8;
        display: 'inline-block';
        margin: '0 8px';
}
.caroInfo{
    color: white;
}
/* SECOND CONTENT SECTION */
.SecondContentContainers {
    /* background: #000; */
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    padding: 1%;
    justify-content: flex-start;
    /* width: 80%; */
}
::-webkit-scrollbar {display:none;}
.ReviewContentContainer
{
    /* height: 50 */
    /* overflow-y: scroll; */
    display: flex;
    margin-top: 2.5%;

    flex-direction: column;
    justify-content: flex-start;
}
.SecondMainContentContainer {
    display: flex;
    flex-direction: row;
    gap: 3%;
    margin-top: 0%;
}

.GameInformationContainer {
    display: flex;
    flex-direction: column;
}
.GameContents {
    /* flex flex-row */
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    justify-content: center;
    align-items: center;
    background-color: #041640;
    border-width: 1;
    border-color: #21217A; 
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border-radius: 24px;
    gap: 3%;
}
.scrolledContents {
    display: flex;
    width: 100%;

    /* gap: 3%; */
    /* background-color: #820202; */
    flex-direction: column;
    /* overflow-y: hidden; */
    /* scroll-behavior: smooth; */
    /* overflow-y: visible; */
    /* overflow: scroll; */
}
.InformationHeaderText {
    color: white;
    font-size: 100%;
}
.ReviewComponentText {
    /* margin-left: 10%; */
    display: flex;
  flex-direction: row;
  gap: 2px;
    color: white;
    font-size: 100%;

}
.GameMediaContainer {
    display: flex;
    width: 100%;
    flex-direction: row; 
    height: 250px;
    /* background: #000; */
    gap: 1.5%;
    margin-top: 1.5%;
}
.LeftImageContainer {
    display: flex;
    width: 50%;
    /* height: 50%; */
}
.RightImageContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 4%;
    /* height: 50%; */

}
.TitleStyle {
    color: white;
    font-size: 28px;
}
.WallContent {
    width: 15%;
}
.DeveloperInfoContent {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 20%;
    /* background-color: #FF0000; */
}
.TopDeveloperCard {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-top: 4%; 
 
    /* width: 100%; */
    background: linear-gradient(180deg, #D7CCFF 0%, #FFFFFF 100%);
    border-radius: 12px;
    padding: 4%;
    padding-top: 3%;
    padding-bottom: 3%;

}
.TDImageContainer {
 
    height: 4vh;
    width: 2.4vw;
    /* background:#FF0000; */
}
.TDImageStyle {
    object-fit: cover;
    height: 100%;
    /* width: 50%; */
    border-radius: 50%;
}
.TDLabelDStyle {
    color: #00022F;
    font-weight: 600;
    font-size: medium;
}
.GameDescText {
    /* text-[#BABEC1] text-justify */
    color: #BABEC1;
    text-align: justify;
    font-size: 100%;
}

/* FOOTER */
.Footer {
    background-color: #000212;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 5%;
}
.ProductLogoContainer {
    display:  flex;
    flex-direction: column;
    width: 30%;
}
.GameCategoryContainer {
    display: flex;
    flex-direction: column;
}
.reviewComponentContainer{
    display: flex;
    flex-direction: row;
    /* margin-top: 6%; */
    /* padding-bottom: 1.5%; */
    padding: 3%;
    gap: 8%;
    padding-right: 1.5%;
    width: 100%;
    justify-content: flex-end;
}
/* THIRD SECTION */
.ThirdContentContainers{
    background-color: #03002C;
    /* width: 20%; */
    display: flex;
    flex-direction: column;
    padding: 2%;
    /* height: 100%; */
    height: fit-content;
    /* align-items: center; */
}
.slick-dots li.slick-active button:before {
    color:White !important;
}
.line-clamps-3{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}