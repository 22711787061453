.custom-loader {
    width: 50px;
    aspect-ratio: 1;
    --c:no-repeat linear-gradient(#1100D0 0 0);
    background: 
      var(--c) 0    0, 
      var(--c) 50%  50%, 
      var(--c) 100% 100%;
    animation: 
      b7 1s infinite alternate;
  }
  
  @keyframes b7 {
   0%,
   10%  {background-size:20% 100%}
   50%  {background-size:20% 20%}
   90%,
   100% {background-size:100% 20%}
  }