.gamesDetailBGContainer {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  overflow: hidden;
}

.gamesDetailBGContainerReady {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* height: fit-content; */
  height: 100%;
  overflow: hidden;
}

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;

}

.mainWrapper {
  height: 100%;
  width: 100%;
}

.react-player {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}

.thumbnailMainContainer {
  width: 100%;
  display: flex;
  height: 20%;
  justify-content: center;
  align-items: center;
  background: red;
}

.thumbnailImgContainer {
  width: 100%;
  height: 50%;
  /* gap: 0; */
  background-color: blueviolet;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.thumbnailVideo {
  width: 100%;
  height: 100%;
  background-size:  contain;
  background-position: center;
  /* object-fit: cover; */
  background-repeat: no-repeat;
}

.slider-parent {
  height: inherit;
  width: auto;
  background-color: rgb(180, 180, 17);
}

.image-item {
  padding: 20px 0 20px 20px;

}

.barContainer {
  background-color: transparent;
  /* border-radius: 12px; */
  height: 80%;
}

.footer-content {
  /* position: fixed; */
  /* display:inline-block; */
  /* float:left; */
  /* pb-[10%] px-[5%] pt-[3%] */
  padding-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  margin-top: 55vh;
  /* bottom: 0; */
  width: 100%;
  background: #000212;
}

/* //scrollbar */
input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 20px;
  background: #041640;
  border: none;
  /* border-radius: 3px; */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 25px;
  width: 15%;
  border-radius: 0%;
  background: #4C4DDC;
  margin-top: -3px;
  border-radius: 6px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
  border-radius: 6px;
}

.carousel-container-with-scrollbar {
  padding-bottom: 1%;
  width: 100%;
  height: fit-content;
}

.custom-slider {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* padding: 0 20px; */
}

.custom-slider__input {
  width: 100%;
}

.slider-image-item {
  padding: 10px 10px 10px 0;
  height: 15vh;
  width: 7.5vw;
  /* width: fit-content; */
  /* background-color: bisque; */
}

[dir="rtl"] .carousel-container {
  direction: ltr;
}

.playLogo {

  width: 100%;
  height: 100%;
  object-fit: contain;

}

.playBtnContainer {
  position: fixed;
  left: 50%;
  width: 50px;
  height: 50px;
  top: 50%;
  opacity: .8;
  background-color: #181332;
  transform: translate(-50%, -50%);
}

.thumbnailContainerSelected {

  width: 100%;
  height: 100%;
  border: 2px solid;
  background: #000;
  border-color: #1b29f3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnailContainer {

  width: 100%;
  height: 100%;
  background: #000;
  ;
  /* border: 4px solid;
  border-color: #FFFF; */
}